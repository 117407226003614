(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/correction-email/assets/javascripts/correction-email.js') >= 0) return;  svs.modules.push('/components/tur/correction-email/assets/javascripts/correction-email.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

(svs => {
  'use strict';

  svs.tur = svs.tur || {};
  svs.tur.CorrectionEmail = function (_options) {
    let options = {
      productName: '',
      callback: undefined
    };
    options = _objectSpread(_objectSpread({}, options), _options);
    const numberProducts = ['Eurojackpot', 'Vikinglotto', 'Lotto', 'Keno'];

    function show() {
      const showIfNeeded = productArray => {
        const shouldShow = productArray.some(product => !product.value);
        if (shouldShow && !svs.components.personalized_storage.get('correction_mail_signup_showed', options.productName)) {
          showDialog();
        } else {
          var _options$callback, _options2;
          (_options$callback = (_options2 = options).callback) === null || _options$callback === void 0 || _options$callback.call(_options2);
        }
      };
      const productArray = [];
      for (let i = 0; i < numberProducts.length; i++) {
        $.when(svs.tur.usersettings.get({
          key: "correctionMail".concat(numberProducts[i])
        })).done(_ref => {
          let {
            key,
            value
          } = _ref;
          key = key.replace('correctionMail', '');
          productArray.push({
            key,
            value
          });
          if (key === numberProducts[numberProducts.length - 1]) {
            showIfNeeded(productArray);
          }
        });
      }
    }

    function showDialog() {
      const signUpDialog = new svs.ui.dialog.Confirm({
        icon: 'e-post',
        branding: svs.ui.dialog.branding.TUR,
        requireHandshake: true,
        title: 'Önskar du en personlig rättningsupplevelse till din e-post?',
        actions: [{
          type: svs.ui.dialog.button.SECONDARY,
          title: 'Nej tack',
          callback: () => {
            saveSuccess();
            signUpDialog.resolveHandshake();
          }
        }, {
          type: svs.ui.dialog.button.SECONDARY,
          title: 'Ja tack',
          showLoader: true,
          callback: () => {
            savePlayerSettings();
            signUpDialog.resolveHandshake();
          }
        }]
      });
      svs.ui.dialog.api.add(signUpDialog);
    }

    function savePlayerSettings() {
      const settings = numberProducts.map(product => ({
        key: "correctionMail".concat(product),
        value: true
      }));
      svs.core.jupiter.call({
        method: 'PUT',
        path: '/player/1/settings',
        data: {
          settings
        }
      }, () => {
        const saveSetting = function () {
          let i = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
          const key = "correctionMail".concat(numberProducts[i]);
          $.when(svs.tur.usersettings.set({
            key,
            value: true
          })).done(() => {
            if (i++ < numberProducts.length) {
              saveSetting(i);
            } else {
              saveSuccess();
            }
          });
        };
        saveSetting();
      }, () => {
        const message = 'Det gick tyvärr inte att spara din inställning för rättningsmail.';
        const signUpDialog = new svs.ui.dialog.Confirm({
          type: svs.ui.dialog.type.ERROR,
          title: 'Tekniskt fel',
          branding: svs.ui.dialog.branding.TUR,
          message: [{
            type: svs.ui.dialog.message.TEXT,
            text: message
          }],
          actions: [{
            type: svs.ui.dialog.button.PRIMARY,
            title: 'Okej'
          }]
        });
        svs.ui.dialog.api.add(signUpDialog);
      });
    }
    function saveSuccess() {
      var _svs$accountservices, _options$callback2, _options3;
      (_svs$accountservices = svs.accountservices) === null || _svs$accountservices === void 0 || (_svs$accountservices = _svs$accountservices.player_notifications_checker) === null || _svs$accountservices === void 0 || (_svs$accountservices = _svs$accountservices.events) === null || _svs$accountservices === void 0 || _svs$accountservices.trigger('email');
      const EXPIRES_4MONTHS = svs.components.Storage.constants.EXPIRES_1MONTH * 4;
      svs.components.personalized_storage.set('correction_mail_signup_showed', options.productName, true, EXPIRES_4MONTHS, (_options$callback2 = (_options3 = options).callback) === null || _options$callback2 === void 0 ? void 0 : _options$callback2.call(_options3));
    }
    return {
      show,
      showDialog,
      savePlayerSettings
    };
  };
})(svs);

 })(window);